import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './Footer2.css';
import logofooter from '../img/logonegativo.svg';
import bar from '../img/barra.png';
import eureka from '../img/footer_eureka.png';
import { Modal } from 'react-bootstrap';
import projectA from "../img/projectoA.png";
import projectB from "../img/projectoB.jpg";
import projectC from "../img/projectoC.png";
import projectD from "../img/Group.png";
import tec4green from "../img/tec4green.jpg"
import projectE from "../img/projectoE.jpg";
import fichaProjetoFSE from "../img/ficha_projeto_FSE_RHAQ_AI_FV_page-0001.jpg";
import adress from '../img/icones_footer/adress.svg';
import facebook from '../img/icones_footer/facebook.svg';
import instagram from '../img/icones_footer/instagram.svg';
import linkedin from '../img/icones_footer/linkedin.svg';
import twitter from '../img/icones_footer/twitter.svg';
import complaint_book from '../img/icones_footer/complaint_book.png';
import BVCertification from '../../src/img/icones_footer/BV_Certification_NP4457.svg';
import barra_2024 from '../../src/img/BARRA_PRR_MONO_2024-02.png';
import politica1 from '../img/politicasustentabilidade1.png';
import politica2 from '../img/politicasustentabilidade2.png';
import politica3 from '../img/politicasustentabilidade3.png';
import ficha_agrosat1 from '../img/Ficha_Agrosatadapt/Ficha_Agrosatadapt-1.png';
import ficha_agrosat2 from '../img/Ficha_Agrosatadapt/Ficha_Agrosatadapt-2.png';
import ficha_landmarc from '../img/Ficha_Landmarc/Ficha_Landmarc-1.png';
import ficha_movaflor from '../img/Ficha_Movaflor/Ficha_Movaflor-1.png';
import ficha_ourmed1 from '../img/Ficha_Ourmed/Ficha_Ourmed-1.png';
import ficha_ourmed2 from '../img/Ficha_Ourmed/Ficha_Ourmed-2.png';
import ficha_pegada1 from '../img/Ficha_Pegada/Ficha_Pegada-1.png';
import ficha_pegada2 from '../img/Ficha_Pegada/Ficha_Pegada-2.png';
import ficha_pestnu1 from '../img/Ficha_PESTNU/Ficha_PESTNU-1.png';
import ficha_pestnu2 from '../img/Ficha_PESTNU/Ficha_PESTNU-2.png';
import cartaz_entidades from '../img/cartaz-entidades.png';
import estagios from '../img/estagios.png';

const Footer2 = (props) => {
    const { t } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("PROJECTS")}</Modal.Title>
                </Modal.Header>
                <Modal.Body><img src={projectA} />
                <img src={projectB} />
                <img src={projectC} />
                <img src={projectD} /> 
                <img src={fichaProjetoFSE}  /> 
                <img src={tec4green} />
                <img src={ficha_agrosat1} />
                <img src={ficha_agrosat2} />
                <img src={ficha_landmarc} />
                <img src={ficha_movaflor} />
                <img src={ficha_ourmed1} />
                <img src={ficha_ourmed2} />
                <img src={ficha_pegada1} />
                <img src={ficha_pegada2} />
                <img src={ficha_pestnu1} />
                <img src={ficha_pestnu2} />
                <img src={estagios} />
                <img src={cartaz_entidades} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <div className="footer-container">
                <div className="upper-footer">
                    <img src={logofooter} style={{ marginBottom: "2em" }} />
                    <div className="adress-container">
                        <div style={{ display: "flex", alignItems: "start" }}>
                            <img src={adress} />
                            <div className="adress-container-left-side">
                                <div className="adress-container-left-side-text">
                                    <span style={{ marginLeft: "1em" }}>Avenida Vicente Salles Guimarães, 455 Bairro Alto Umuarama</span>
                                    <span style={{ marginLeft: "1em" }}>Uberlândia-MG - <span style={{ fontWeight: "bold" }}>BRASIL</span></span>
                                </div>

                            </div>
                        </div>

                        <div style={{ display: "flex", alignItems: "start" }}>
                            <img src={adress} />
                            <div className="adress-container-right-side">
                                <span style={{ marginLeft: "1em" }}>Europe - Headquarters Rua Circular Norte, Edifício NERE </span>
                                <span style={{ marginLeft: "1em" }}>Sala 12.10 7005-841 Évora - <span style={{ fontWeight: "bold" }}>PORTUGAL</span></span>
                            </div>
                        </div>

                    </div>
                    <div className="adress-container-left-side-icons">
                        <span style={{ marginLeft: "1em" }}>{t("FOLLOW")}</span>
                        <a className="link" target="_blank" href="https://www.facebook.com/Agroinsider/"> <img src={facebook} style={{ marginRight: "1em" }} /> </a>
                        <a className="link" target="_blank" href="https://twitter.com/agroinsider?lang=en"> <img src={twitter} style={{ marginRight: "1em" }} /></a>
                        <a className="link" target="_blank" href="https://www.linkedin.com/company/agroinsider"><img src={linkedin} style={{ marginRight: "1em" }} /></a>
                        <a className="link" target="_blank" href="https://www.instagram.com/agroinsider_pt/"> <img src={instagram} style={{ marginRight: "2em" }}/></a>
                        <a className="link" target="_blank" href="https://www.livroreclamacoes.pt/Inicio/"> <img src={complaint_book} /> </a>
                    </div>

                </div>


                <div className="dowm-footer">
                    <hr className="hr-style" />
                    <div className="down-footer-container">
                        <span className="copyright" >Copyright@Agroinsider2021</span>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                            <img src={bar} style={{ width: "25vw", height: "2.5vw" }} />
                            <img src={barra_2024} style={{width: "500px", marginTop:"1em"}} />
                        </div>
                        <div style={{ display: "flex", alignItems:"center" }}>
                            <div style={{ display: "flex", flexDirection: "column", marginRight: "2em" }}>
                                <span>Reconhecimentos</span>
                                <div style={{display:"flex", width:"20vw"}}>
                                    <img src={eureka} style={{ width: "50%", marginRight:"1em"}} />
                                    <img src={BVCertification} style={{ width: "50%"}} />
                                </div>
                            </div>
                            <span style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", marginRight: "1em" }}>SME instruments</span>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                <button type="button" onClick={handleShow} className="projects-button">{t("PROJECTS")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>

    )
}


export default withTranslation()(Footer2);